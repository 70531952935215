<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key" >                
                            
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage('health_canada', item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >

                                <td v-if="column.Status && column.code == 'hc_001'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <!-- <td v-else-if="column.Status && column.code == 'hc_003'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id, 'healthcanada')"></span>
                                </td> -->
                                <td v-else-if="column.Status && column.code == 'hc_032'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_004'" :code="column.code">
                                    <span v-html="column_status(item)"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_005'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_006'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_007'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.health_canada.date_decision))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_008'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_009'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_010'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_011'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_012'" :code="column.code">
                                    {{check_empty(item.health_canada.submission)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_013'" :code="column.code">
                                    {{check_empty(item.health_canada.control_number)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_014'" :code="column.code">
                                    {{check_empty(item.health_canada.link_id)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_015'" :code="column.code">
                                    {{check_empty(item.health_canada.decision_issued)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_016'" :code="column.code">
                                    {{check_empty(item.health_canada.prescription_status)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_017'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.health_canada.date))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_018'" :code="column.code">
                                    <Readmore :longText="item.health_canada.purpose_submission"/>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_019'" :code="column.code">
                                    <Readmore :longText="item.health_canada.decision_issued_text"/>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_020'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_021'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'hc_022'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>
                                
                                <template v-else-if="column.Status && column.code == 'hc_023'" :code="column.code">
                                    <td :key="column.code+'_024'" :code="'hc_024'" >
                                        {{check_empty(DDMMMYY(item.health_canada.hta_process_date))}}
                                    </td>
                                    <td :key="column.code+'_025'" :code="'hc_025'">
                                        <span :class="color_rembursement(item.health_canada.hta_process)">{{check_empty_with_locale(item.health_canada.hta_process,'hta2_')}}</span>
                                    </td>
                                    <td :key="column.code+'_026'" :code="'hc_026'">
                                        {{check_empty(DDMMMYY(item.health_canada.rembursement_process_date))}}
                                    </td>
                                    <td :key="column.code+'_027'" :code="'hc_027'">
                                        <span :class="color_rembursement(item.health_canada.rembursement_process)">{{check_empty_with_locale(item.health_canada.rembursement_process, 'rb2_')}}</span>
                                    </td>
                                </template>

                                <template v-else-if="column.Status && column.code == 'hc_028'" :code="column.code">
                                    <td :key="column.code+'_029'" :code="'hc_029'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_030'" :code="'hc_030'">
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                    </td>
                                    <td :key="column.code+'_031'" :code="'hc_031'">
                                        <span v-html="datediff(item.ma_date, item.rembursement_process_date)"></span>
                                    </td>
                                </template>

                            </template>

                        </tr>
                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>
        <!-- <ModalHTAstatus :item="item_hta_status"/>       -->
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import { isAdmin, column_status, hta_process_status_2, rembursement_status_2, overview_regulatory_status, 
        modifyPage, ema_id, icon_link, DDMMMYY, check_empty, nct_number, treatment_line, datediff, array, 
        color_rembursement, check_empty_with_locale } from '@/utils'
export default {
    name: "HealthCanada",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        // ModalHTAstatus,
    },
    data : function() {
        return { 
            item_hta_status : {},
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        overview_regulatory_status,
        column_status,
        hta_process_status_2,
        rembursement_status_2,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_rembursement,
        check_empty_with_locale,
        getResults(page = 1) {
            console.log(page)
        },
        hta_status_modal(item){
            this.item_hta_status = item
        },
        modifyPage
    }
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>